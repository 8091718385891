import React from 'react'
import Img from 'gatsby-image'
import Overview from "../components/overview";
import Layout from "../containers/layout";
import { graphql } from "gatsby"

import injectSheet from 'react-jss';

const styles = theme => {
    return {
        image: {
            background: 'white',
            // m = (x - x*r/r_2)/2
            paddingRight: 'calc(100% * (.5 - 27/64))',
            paddingLeft: 'calc(100% * (.5 - 27/64))',
        },
    }
};

let PartnerOverview = ({title, partners, classes}) => {
    partners = partners.sort(({node: a}, {node:b}) => {
        let prioA = 0, prioB = 0;
        if(a.custom_fields.priority){
            prioA = a.custom_fields.priority[0];
        }
        if(b.custom_fields.priority){
            prioB = b.custom_fields.priority[0];
        }
        return prioA-prioB;
    }).map( partner => {
        const {id, featured_media, date, slug, custom_fields, title, content} = partner.node;
            let image = null;
            if(featured_media.localFile) {
                let fluid = featured_media.localFile.childImageSharp.fluid;
                image = (
                    <div className={classes.image}>
                        <Img fluid={fluid}/>
                    </div>
                );
            }
            return {
                key: id,
                ...custom_fields,
                colorize: false,
                image,
                children: (
                    <>
                        <h4 dangerouslySetInnerHTML={ { __html: ( title ) } } />
                        <div dangerouslySetInnerHTML={ { __html: ( content ) } } />
                    </>
                )
            };
        }
    );
    return (
        <Overview header={title} content={partners} className={'container'}/>
    );
};

PartnerOverview = injectSheet(styles)(PartnerOverview);

const priorityGroups = ['unterst', 'sponsor', 'partner'];

const PartnerPage = ({data}) => {
    let groupByPrio = [];
    let rest = [];
    data.partnersByArt.group.forEach(group => {
        let prio = priorityGroups.findIndex(slug => group.fieldValue.toLowerCase().indexOf(slug)===0);
        if(prio>=0)
            groupByPrio[prio] = group;
        else
            rest.push(group);
    });
    groupByPrio.concat(rest);

    return (
        <Layout pageTitle={"Partner"}>
            {
                groupByPrio.map(group => {
                    return (
                        <PartnerOverview
                            key={group.fieldValue}
                            title={group.fieldValue}
                            partners={group.edges}
                        />
                    );
                })
            }
        </Layout>
    );
};

export default PartnerPage;

export const query = graphql`
    query PartnerPageQuery {
        partnersByArt: allWordpressWpPartners{
            group(field: custom_fields___taxonomies___name){
                fieldValue
                totalCount
                edges{
                    node{
                        title
                        content
                        featured_media{
                            localFile{
                                childImageSharp {
                                    fluid(maxWidth: 2048) {
                                        ...GatsbyImageSharpFluid_tracedSVG
                                    }
                                }
                            }
                        }
                        custom_fields{
                            website: wpcf_website
                            facebook: wpcf_facebook
                            instagram: wpcf_instagram
                            priority: wpcf_priority
                            taxonomies{
                                name
                            }
                        }
                    }
                }
            }
        }
    }
`;